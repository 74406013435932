.pageHeader {
  .logo {
    float: right;

    img {
      width: 168px;
      height: 146px;
      align-content: end;
    }

    @media screen and (max-width: 768px) {
      img {
        width: 54px;
        height: 100%;
      }
    }
  }

  .languageSelector {
    text-align: right;
    margin: 10px;

    font-size: 13px;
    line-height: 1.7;
    font-family: 'Roboto', Helvetica, sans-serif;

    a {
      color: gray;

      &:hover {
        text-decoration: underline;
      }
    }

    a.active {
      color: black;
      text-decoration: underline;
    }
  }

  h2 {
    text-transform: uppercase;
    padding-top: 1em;
    color: #000;
    display: block;
    font: normal 80px/85px 'Fjalla One', arial, helvetica, sans-serif;
    letter-spacing: 1px;
    margin: 0;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media screen and (max-width: 48em) {
      font-size: 40px;
      line-height: 45px;
    }
  }

  h3 {
    color: #000;
    display: block;
    font: normal 30px/34px 'Fjalla One', arial, helvetica, sans-serif;
    border-bottom: 5px solid #000;
    letter-spacing: 1px;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    text-transform: uppercase;
    width: 100%;
  }
}

.person {
  h2 {
    text-transform: unset;
    font-size: 40px;
    line-height: 40px;
  }

  h3 {
    font-size: 50px;
    line-height: 55px;
  }

  @media screen and (max-width: 480px) {
    h3 {
      font-size: 40px;
      line-height: 45px;
    }
  }

  @media screen and (max-width: 400px) {
    h3 {
      font-size: 30px;
      line-height: 35px;
    }
  }
}

.back {
  font-size: 20px;
  padding-top: 1rem;

  a {
    color: gray;
  }

  a:hover {
    color: #000;
    text-decoration: underline;
  }
}