@import '../../components/_variables.scss';

.tile {
	padding: 1rem;
}

.tileInner {
  cursor: pointer;
  
  display: block;

	text-align: center;
	font-size: 1.25em;
	padding-top: 2em;
	padding-bottom: 2em;

	background-color: white;
	border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  
  color: $label-color;

  &:hover, &:active {
    color: $label-color;
  }
  &:hover {
    background-color: #f8f8f8;
  }
}
