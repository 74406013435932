.header {
  background-color: white;
  background-image: url('../../assets/images/bg-shadow.png');
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  box-shadow: 0 8px 8px -4px #ccc;
  padding: 10px;

  line-height: 1.7;
  font-size: 13px;
}

.storyPreview {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.9rem;
}