.nodrag {
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -o-user-select: none; 
  user-select: none; 
}

.closebutton {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 2rem;
  color: white;
  line-height: 0;

  i {
    background-color: black;
    border-radius: 1em;
    padding: 1px;
    cursor: pointer;
  }

  @media screen and (max-width: 48em) {
    top: 1rem;
    right: 1rem;
  }
}

.zoomButtons {
  position: absolute;
  bottom: 2rem;
  right: 2rem;

  i {
    margin-left: 0.5em;
  }

  i:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: 48em) {
    top: 1rem;
    left: 1rem;
  }
}

.caption {
  position: absolute;
  bottom: 1rem;
  padding: 1rem;
  background-color: white;
  color: black;
  max-width: calc(100vw - 20rem);

  @media screen and (max-width: 48em) {
    max-width: 100vw;
  }
}
