//.header {
//  background-color: white;
//  background-image: url('../../assets/images/bg-shadow.png');
//  background-size: cover;
//  background-attachment: fixed;
//  overflow: hidden;
//  box-shadow: 0 8px 8px -4px #ccc;
//  padding: 10px;
//
//  line-height: 1.7;
//  font-size: 13px;
//}


//.mapboxgl-popup {
//  max-width: 400px;
//}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Poppins Regular 400', sans-serif;
  font-size: 40px;
  max-width: 600px;
  min-width: 280px;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}


//.map-container {
//height: 100%;width: 100%;
//}