.content {
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 13px;
    color: #555;

    :global(.control-label) {
        padding-top: 8px;
        margin-bottom: 0.2rem;
        line-height: 1.4;
    }

    :global(.form-control) {
        font-size: 13px;
        padding: 6px 12px;
        height: 32px;
    }

    h3 {
        font-family: 'Fjalla One', arial, helvetica, sans-serif;
        letter-spacing: 1px;
    }

    :global(.function-input-group) {
        border-width: 1px;
        border-color: #c31a31;
        border-radius: 4px;
        border-style: solid;
        margin-bottom: 0.5rem;

        h4 {
            margin: 0;
            padding: 4px 20px;
            font-size: 16px;
            font-weight: bold;
            background-color: #c31a31;
            color: white;
        }

        :global(.function-input-group-inputs) {
            padding: 1rem;
        }
    }

    :global(.event-icon) {
        border-color: #c31a31;
        color: #c31a31;
        background-color: white;
    }

    :global(.events-wrapper) {
        border-left: 2px solid #c31a31;
        margin-left: 26px;
        padding-top: 1rem;

        &>div {
            margin-left: -26px;
        }
    }

    :global(.card) {
        /* Styling of events */
        h4 {
            color: #c31a31;
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.filtericon {
    background: #c31a31;
    color: #fff;
    padding: 8px 15px;
    border-radius: 3px;
    font-size: 12px;
}

.advancedSearchRow {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    min-height: 40px;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    :global(.col-sm-1),
    :global(.col-sm-4) {
        align-items: center;
    }
}

.actions {
    float: right;

    :global(.btn) {
        margin-right: 10px;
        background-color: #c31a31;
        border: none;

        &:active,
        &:hover {
            background-color: lighten(#c31a31, 10%) !important;
            border: none;
        }
    }

    :global(.btn):last-child {
        margin-right: 0;
    }
}

.erased {
    text-decoration: line-through;
}

.memorialTag {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.ellipsized {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.militaryInfoColumn {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.nowrap {
    white-space: nowrap;
}

.table {
    td {
      padding: .3rem .6rem !important;
    }

    :global(.badge) {
        background-color: #c31a31;
    }
}
