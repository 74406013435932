@import "custom";
//@import "~bootstrap/scss/bootstrap";

.sidebar-mini .main-nav-link i {
  font-size: 20px !important;
  padding: 4px;
}

.navbar .dropdown-toggle {
  color: white;
  position: relative;
  bottom: 2px;
}

.clickable-card {
  cursor: pointer;
}

.clickable-card:hover {
  background-color: #f8f8f8;
}

.tagview {
  display: inline-block;
  margin: 0.5em;
}

.clickable-icon {
  cursor: pointer;
  color: #007bff;
}

.clickable-icon:hover {
  color: #0056b3;
}

.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #f8f8f8;
}

.deleted-link {
  color: #dc3545;

  :hover {
    color: #c82333;
  }
}

.form-check .form-check-sign::after {
  font-family: 'Font Awesome 5 Pro' !important;
}

.pagination .page-item .page-link {
  width: 33px;
  height: 33px;
  white-space: nowrap;
  text-align: center;
  padding: 5px 0px;
}

.pagination .page-item.active .page-link {
  width: 33px;
  height: 33px;
  white-space: nowrap;
  text-align: center;
  padding: 7px 0px;
}

.main-panel {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.table-with-links {
  .card-body {
    .table {
      tr td {
        padding: 7px 5px !important;
      }

      tr td:first-child,
      tr td:last-child {
        padding: 7px 15px !important;
      }
    }
  }
}


.link {
  text-decoration: underline;
}

.link:hover {
  text-decoration: underline;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.table .td-actions {
  padding-top: 4px;
  padding-bottom: 4px;
}

.card .card-title {
  font-size: 0.9em;
  color: #888;
}

.form-control {
  padding-bottom: 4px;
}

.form-group {
  margin-bottom: .5rem;
}

.form-control-plaintext {
  position: relative;
  top: 2px;

  a {
    padding-top: 6px;
    display: inline-block;
  }
}

.rbt-menu.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  font-size: 12px;
}

.rbt-highlight-text {
  padding-left: 0;
  padding-right: 0;
}

.breadcrumb {
  margin: 0;
  background-color: transparent;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}