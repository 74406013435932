.contactMoment {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1)
  }
}

.contactMomentMain {
  display: block;
  flex-grow: 1;

  color: white;
  &:hover, &:active, &:focus {
    color: white;
  }
}
