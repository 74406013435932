.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

.wrapper {
  width: 100%;
  position: relative;
  flex-grow: 1;
  display: flex;
}

.previous {
  position: absolute;
  top: 50%;
  font-size: 3em;

  i {
    margin-top: -50%;
  }
}

.next {
  position: absolute;
  top: 50%;
  font-size: 3em;
  right: 0;

  i {
    margin-top: -50%;
  }
}

.filename {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    background-color: white;
    padding: 0.5em;
  }
}

.viewerWrapper {
  width: 100%;
  flex-grow: 1;
  position: relative;
}

.zoomin {
  position: absolute;
  top: 4.5em;
  right: 1em;
}

.zoomout {
  position: absolute;
  top: 1em;
  right: 1em;
}

.fullscreen {
  position: absolute;
  top: 8em;
  right: 1em;
}

.open {
  position: absolute;
  top: 11.5em;
  right: 1em;
}

.centered {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
