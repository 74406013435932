.header {
  background-color: white;
  background-image: url('../../assets/images/bg-shadow.png');
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  box-shadow: 0 8px 8px -4px #ccc;
  padding: 10px;

  line-height: 1.7;
  font-size: 13px;
}

.event {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eventLineTop {
  position: absolute;
  width: 2px;
  background-color: #c31a31;
  top: 0;
  bottom: calc(50% + 1.5rem);
  left: calc(1.5rem - 1px);
}

.eventLineBottom {
  position: absolute;
  width: 2px;
  background-color: #c31a31;
  top: calc(50% + 1.5rem);
  bottom: 0;
  left: calc(1.5rem - 1px);
}

.event:first-child {
  padding-top: 1rem;

  .eventLineTop {
    bottom: calc(50% + 1rem);
  }

  .eventLineBottom {
    top: calc(50% + 2rem);
  }
}

.eventIconWrapper {
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.eventIcon {
  font-size: 1.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: 2px solid #c31a31;
  border-radius: 5rem;
  padding-top: 5px;
  color: #c31a31;
}

.eventInfo {
  flex-grow: 1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  h4 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #c31a31;
  }

  dl {
    margin-bottom: 0;

    dd {
      font-size: 0.9rem;
      font-weight: 400;

      p {
        font-size: 0.9rem;
      }
    }

    dt {
      font-weight: 400;
      font-size: 0.9rem;
      color: #9A9A9A;
    }
  }
}
