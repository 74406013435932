
.iconbutton {
	font-size: 2em;
	color: black;
	background-color: white;
	padding: 0.2em;
	border-radius: 15%;
	border: 1px #ccc solid;
  cursor: pointer;
  width: 1.45em;
  text-align: center;
  
  &:hover {
    background-color: #eee;
  }
}
