.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  margin-top: 4rem;
  margin-bottom: 10rem;
  
  @media screen and (max-width: 992px) {
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

.image {
  width: 216px;
}

.name {
  color: white;
  margin-left: 1rem;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }

  .firstName {
    padding-top: 0.5rem;
    font: normal 40px/45px 'Fjalla One', arial, helvetica, sans-serif;
    letter-spacing: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }

  .lastName {
    font: normal 50px/55px 'Fjalla One', arial, helvetica, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
  }
}
