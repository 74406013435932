@import '../_variables.scss';

.languageSelector {
  //color: white;

  a {
    font-size: 0.8rem;
  }
}

.user {
  color: $label-color;
}


.page_title {
  font-size: 18px;
  font-weight: bold;
  color: #2b2d42;
}
