.photo {
  margin: 0.5em 1.5em;
}

.photoSelector {
  margin: 0.5em;
}

.photo, .photoSelector {
  box-shadow: 0px 1px 3px #888;
  border-radius: 3px;
  position: relative;

  .wrapper {
    cursor: pointer;

    padding-left: 11px;
    padding-right: 11px;
    padding-top: 17px;
    padding-bottom: 17px;

    background-color: white;
    transition: background-color 0.25s;

    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  span {
    display: inline-block;
    padding-top: 10px;
    font-size: 14px;
    color: #aaa;

    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  svg, img {
    max-width: 175px;
    max-height: 175px;
  }

  .delete {
    color: #DE5050;
    position: absolute;
    top: -4px;
    right: 0px;
    font-size: 20px;
    padding: 4px;

    cursor: pointer;
    transition: color 0.25s;

    &:hover {
      color: #E67979;
    }
  }
}
