.event {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eventIconWrapper {
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.eventIcon {
  font-size: 1.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: 1px solid #888;
  border-radius: 5rem;
  padding-top: 4px;
  color: #888;
}

.eventInfo {
  flex-grow: 1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  h4 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: #7e8890;
  }

  dl {
    margin-bottom: 0;

    dd {
      font-size: 0.9rem;
      font-weight: 400;

      p {
        font-size: 0.9rem;
      }
    }

    dt {
      font-weight: 400;
      font-size: 0.9rem;
      color: #9A9A9A;
    }
  }
}

.personInfo {

  margin-left: 4px;

  h1 {
    font-size: 2rem;
    font-weight: 400;
    color: #7e8890;
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9rem;
    font-weight: 400;
    color: #7e8890;
  }

  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9rem;
  }
}

