.remove {
  cursor: pointer;

  display: inline-block;
  padding: 3px;

  &:hover {
    color: #ccc;
  }
}
