@import './_variables.scss';

.section {
  position: relative;
  font-size: 1.1rem;
  margin: 1rem;
}

.label {
  position: absolute;
  top: 0.7rem;
  left: 0.7rem;

  i {
    font-size: 18px !important;
    color: $label-color;
  }
}

.input {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  font-size: inherit;
  border-radius: 1.2rem;
  height: 2.2em;
}

.clear {
  position: absolute;
  top: 0.2rem;
  right: 0;
  text-decoration: none !important;
}

.clear:hover {
  text-decoration: none;
}
