.elementList {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
}

.addPage {
  box-shadow: 1px 1px 4px #888;
  width: 150px;
  height: 200px;
  margin: 0.5em;
}

.addSubdocument {
  box-shadow: 1px 1px 4px #888;
  width: 150px;
  height: 200px;
  margin: 0.5em;
  position: relative;
  left: 1px;
  top: 1px;
  background-color: white;
}

.addSubdocumentInner {
  position: relative;
  right: 1px;
  bottom: 1px;
  box-shadow: 1px 1px 4px #888;
  width: 150px;
  height: 200px;
  margin: 0.5em;
  background-color: white;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.active {
  background-color: #eee;
}


.tagtypeselection {
  color: #9A9A9A;
  font-size: 0.75rem;
}

