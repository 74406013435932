.outer {
  position: relative;
  box-shadow: 1px 1px 4px #888;
  width: 150px;
  height: 200px;
  margin: 0.5em;
  cursor: pointer;

  img {
    position: absolute;
    width: 100%;
    max-height: 100%;
    clip: rect(0px,150px,200px,0px);
  }

  align-items: center;

}

.elements {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-height: 100%;
}


.central {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.subdocumentInner {
  position: relative;
  right: 0px;
  bottom: 0px;
  box-shadow: 1px 1px 2px #888;
  width: 65px;
  height: 75px;
  margin: 5px;
  background-color: white;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  img {
    width: 100%;
    max-height: 100%;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.centerTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
}

.checkbox {
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.pageInfo {
  font-size: 12px;
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.expandIcon {
  font-size: 10px;
  position: absolute;
  top: 6px;
  right: 6px;
}

.nodrag {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
