.tagtitle {
  margin-bottom: 5px;
}

.tagsubtitle {
  color: #888;
  font-size: 0.75rem;
  margin-bottom: 15px !important;
  text-transform: none;
}

.deleteButton {
  font-size: 14px;
  position: absolute !important;
  top: 6px !important;
  right: 6px !important;
}
