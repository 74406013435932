@import '../../components/_variables.scss';


.erased {
  text-decoration: line-through;
}

.personNumberBadge {
  position: relative;
  bottom: 1px;
  margin: 3px;
}

.personEventBadge {
  cursor: pointer;
  margin: 3px;
  font-size: 100%;
  color: $second-button-color;
  background-color: $second-button-bg-color;
}

.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
