
.uploadGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFA534;
  border-radius: 5px;
  flex-direction: column;
}

.fileinputButton {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.fileinputButton input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: alpha(opacity=0);
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

/* Fixes for IE < 8 */
@media screen\9 {
  .fileinputButton input {
    filter: alpha(opacity=0);
    font-size: 100%;
    height: 100%;
  }
}
