.clickableRow {
  transition: background 0.15s;

  &:hover {
    background-color: #eee !important; // to make it override striped
  }
}

.clickableCell {
  cursor: pointer;
}

.sortableHeader {
  cursor: pointer;
}

.th {
  //white-space: nowrap;
}

.ellipsize {
  max-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noValue {
  color: #888;
}

.table {
  table-layout: fixed;
  color: #616161;
}

.filterPopout {
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  z-index: 10;

  :global(.form-check-label) {
    padding-left: 8px;
  }
}

.filterButton,
.filterButtonActive {
  padding: 0px 5px;
  display: inline-block;
  cursor: pointer;
}

.filterButtonActive {
  color: black;
}