.title {
  font-size: 14px;
  border-bottom: 1px solid #ddd !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.name {
  font-weight: bold;
}

.card {
  margin-bottom: 10px;
}
