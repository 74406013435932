.header {
  font-size: 18px;
  color: #8F8F8F;
}

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.photoItem {
  width: 150px;
  height: 200px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  img {
    max-width: 150px;
    max-height: 200px;
  }
}
