.erased {
  text-decoration: line-through;
}

.memorialTag {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.ellipsized {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.militaryInfoColumn {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}
