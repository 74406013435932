.story {
  background-color: #3A383B;
  box-shadow: 0 8px 8px -4px #ccc;

  line-height: 1.7;
  font-size: 15px;
}

.storyWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.storyIconWrapper {
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.storyLine {
  position: absolute;
  width: 2px;
  background-color: #888;
  top: 4rem;
  bottom: 0;
  left: calc(1.5rem - 1px);
}

.storyIcon {
  font-size: 1.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: 2px solid #888;
  border-radius: 5rem;
  padding-top: 2px;
  color: #888;

  img {
    width: 40px;
  }
}

.storyPersonPhoto {
  margin-bottom: -2em;
  position: relative;
  display: inline-block;

  img {
    width: 300px;
    border-radius: 5px;
  }
  @media screen and (max-width: 600px) {
    img {
      width: 160px;
    }
  }

  .fadeLeft {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(#343233, 0.4), rgba(#343233, 0));
  }

  .fadeRight {
    position: absolute;
    top: 0;
    right: 0px;
    width: 75px;
    height: 100%;
    background-image: linear-gradient(to left, rgba(#343233, 1), rgba(#343233, 0));
  }

  .fadeTop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(to bottom, rgba(#343233, 0.8), rgba(#343233, 0));
  }

  .fadeBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background-image: linear-gradient(to top, #343233, rgba(#343233, 0));
  }
}

.storyContent {
  background-color: #343233;
  color: white;
  border: 2px solid #646466;
  border-radius: 6px;
  margin-top: 1em;
  margin-bottom: 2em;
}
