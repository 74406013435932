.buttonRow {
   display: flex;
   justify-content: flex-end !important;

   button {
     margin-left: 1em !important;
   }

   button:first-child {
     margin-left: 0;
   }
 }

.buttonRowTop {
  display: flex;
  justify-content: flex-start !important;

  button {
    margin-left: 1em !important;
  }

  button:first-child {
    margin-left: 0;
  }
}

.bottomRow {
  position: fixed;
  bottom: 0;
  left: 260px;
  right: 0;
  padding: 5px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
}

:global(.sidebar-mini) .bottomRow {
  left: 80px;
}
