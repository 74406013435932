.eventHeader {
  border-bottom: 1px solid#ccc !important;
  
  :global .modal-title {
    text-align: center;
    margin-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
}


.addEventIcon {
  padding: 10px;
  margin-right: 15px;
}

.editDocumentDescriptionIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.documentsLayout {
  display: flex;
  flex-direction: row;
}

.documentThumbnailList {
  flex-grow: 0;
  padding-right: 10px;
  width: 200px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.documentThumbnail {
  margin: 10px;
  font-size: 13px;
  line-height: 1.7;
}

.documentThumbnailInner {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  width: 120px;
  height: 140px;
  padding: 10px;
  box-shadow: 0 0 5px #888;
  background-color: #FAFAFA;
  transition: All 0.5s ease;
  cursor: pointer;
}

.documentThumbnailInnerSelected {
  background-color: #EDB09C;
  cursor: default;
}

.documentThumbnailHeader {
  font-weight: bold;
}

.documentThumbnailImages {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
