$image-width: 270px;
$image-height: 320px;

$medium-image-width: 180px;
$medium-image-height: 214px;

$small-image-width: 90px;
$small-image-height: 107px;

.carrouselWrapper {
  display: flex;
  flex-direction: row;
}

.carrousel {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;

  .images {
    display: flex;
    flex-direction: row;
    width: $image-width;
    height: $image-height;
    align-items: center;
    justify-content: center;
  }

  img {
    max-height: $image-height;
    max-width: $image-width;
    cursor: pointer;
  }

  @media screen and (max-width: 48em) {
    .images {
      width: $medium-image-width;
      height: $medium-image-height;
    }

    img {
      max-width: $medium-image-width;
      max-height: $medium-image-height;
    }
  }

  @media screen and (max-width: 24em) {
    .images {
      width: $small-image-width;
      height: $small-image-height;
    }

    img {
      max-width: $small-image-width;
      max-height: $small-image-height;
    }
  }


  .prevImg {
    position: relative;
    height: $image-height;
    width: 24px;
    background-position: right;
    background-size: cover;

    .gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right, #343233, rgba(#343233, 0));
    }
  }

  @media screen and (max-width: 48em) {
    .prevImg {
      height: $small-image-height;
    }
  }

  .nextImg {
    position: relative;
    height: $image-height;
    width: 24px;
    background-position: left;
    background-size: cover;

    .gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to left, #343233, rgba(#343233, 0));
    }
  }

  @media screen and (max-width: 48em) {
    .nextImg {
      height: $small-image-height;
    }
  }
}

.previousButton,
.nextButton {
  font-size: 40px;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
}

.indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  div {
    width: 10px;
    height: 10px;
    border: 1px solid white;
    border-radius: 5px;
    margin: 5px;
  }

  .current {
    background-color: white;
  }
}