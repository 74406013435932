.splitpane {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.dividerWrapper {
  padding: 15px;
  cursor: ew-resize;
  user-select: none;
}

.divider {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 5px;
  user-select: none;
  background-color: #fff;
  height: 100%;
}
