.inputGroupButton:hover {
  background-color: lighten(#e9ecef, 2%);
  cursor: pointer;
  color: #888;
}

.placeLabel {
  font-size: 0.9em;
  color: #888;
}

.option {
  padding: 0.25em;
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionLeft {
  padding-right: 0.5em;
}

.optionRight {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
}

.placetype {
  color: #888;
  font-style: italic;
}
