.header {
  background-color: white;
  background-image: url('../../assets/images/bg-shadow.png');
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  box-shadow: 0 8px 8px -4px #ccc;
  padding: 10px 10px 10px 0px;

  line-height: 1.7;
  font-size: 15px;
}

.story {
  background-color: #3A383B;
  box-shadow: 0 8px 8px -4px #ccc;

  line-height: 1.7;
  font-size: 15px;
  padding-left: 12px
}
