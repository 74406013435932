:global(.btn.btn-link) {
  &.editbutton {
    padding-left: 12px;
    padding-right: 12px;
  }
  &.inverted {
    color: white !important;
    background-color: #447DF7 !important;
    border-radius: 4px;
  }
}
