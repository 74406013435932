.btn {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;

  border: none;
  box-shadow: none;
  text-shadow: none;
  padding: 8px 20px;
  border-radius: 3px;
  transition: All 0.5s ease;

  background: #c31a31;
  color: #fff !important;

  &:hover {
    box-shadow: none;
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
    text-decoration: none;
    outline: thin dotted #333;
    outline-offset: -2px;
  }
}
